import React from 'react';

import {
    ClientsLogoContainer,
    HeroBackground,
    StatisticsContainer,
    TextLines,
} from '../../components';
import WorkWithUs from '../../components/work-with-us/WorkWithUs';

import PortfolioHeroSection from './section/portfolio-hero-section/PortfolioHeroSection';
import PortfolioReferencesSection from './section/portfolio-references-section/PortfolioReferencesSection';

type Props = {};

const PortfolioPage = (props: Props) => {
    return (
        <div className="w-screen overflow-hidden">
            <HeroBackground />
            <PortfolioHeroSection />
            <TextLines />
            <PortfolioReferencesSection />
            <StatisticsContainer />
            <ClientsLogoContainer />
            <WorkWithUs />
        </div>
    );
};

export default PortfolioPage;
