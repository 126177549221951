import React from 'react';

import oval from '../../images/Ellipse 3.svg';
import { GradientCircle } from '../../ui';

type Props = {};

const HeroBackground = () => {
    return (
        <div className="relative w-screen -z-[1]">
            <GradientCircle
                size={800}
                colors={[
                    'rgba(166, 145, 92, 0.44) 0%',
                    ' rgba(48, 31, 51, 0.07) 100%',
                ]}
                classesName="-right-96 -top-96 z-[10]"
            />
            <div className="opacity-40">
                <img src={oval} className="absolute right-36 w-1/2 top-0" />
                <img src={oval} className=" absolute w-1/3 top-0" />
                <img
                    src={oval}
                    className="absolute transform rotate-12 right-1/4 -top-40 w-3/4"
                />
            </div>
        </div>
    );
};

export default HeroBackground;
