import React from 'react';

import { SERVICES_DATA } from '../../../../data/ServicesData';
import icon from '../../../../images/service_icon.svg';
import { AnimatedDiv } from '../../../../ui';

type Props = {};

const ServicesOurServicesSection = (props: Props) => {
    const data = SERVICES_DATA.OUR_SERVICES;
    const _renderServiceCard = (item, index) => {
        return (
            <AnimatedDiv
                axis="y"
                axisValue={50 * (index + 1)}
                transition={{ duration: 0.1 * (index + 1) }}
                className="flex flex-col md:w-[30%] w-[47%] h-full min-h-[18rem] md:p-8 p-3 gap-2 md:gap-4 2xl:gap-6 rounded-2xl md:rounded-[28px] justify-center items-center gradient-blur-light">
                <img
                    src={icon}
                    className="gradient-blur-light md:p-3 p-1 rounded-lg md:rounded-2xl"
                />
                <span className="app-subtitle text-center">{item.title}</span>
                <span className="text-center md:text-base text-[10px]">
                    {item.desc}
                </span>
            </AnimatedDiv>
        );
    };
    return (
        <div className="flex flex-col app-px">
            <div className="flex w-full justify-center my-6">
                <span className="gradient-text text-4xl md:text-7xl font-bold">
                    Our Services
                </span>
            </div>
            <div className="flex flex-wrap md:gap-10 gap-4 py-8 items-center justify-between">
                {data?.map((item, index) => _renderServiceCard(item, index))}
            </div>
        </div>
    );
};

export default ServicesOurServicesSection;
