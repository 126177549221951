import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { FOOTER_SOCIAL_MEDIA } from '../../data/FooterLinks';
import { CONTACT_INFO } from '../../data/HomeData';
import address from '../../images/address.svg';
import email from '../../images/sms-tracking.svg';
import { AnimatedDiv, AppButton } from '../../ui';
import { sendEmail } from '../../utils/contactUtils';

import classes from './ContactFormTransparent.module.css';

type Props = {};

const ContactFormTransparent = (props: Props) => {
    const [success, setSuccess] = useState(false);

    const submitHandler = (e: React.FormEvent<HTMLFormElement> | any) => {
        e.preventDefault();
        console.log(e.target);
        sendEmail(e.target, () => setSuccess(true));
    };

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        }
    }, [success]);

    return (
        <div className="flex flex-col md:flex-row my-6 md:my-12 rounded-3xl gap-10 justify-between">
            <div className="md:w-3/5 ">
                {success && (
                    <AnimatedDiv
                        axis="y"
                        axisValue={-50}
                        className="p-2 min-h-[50px] flex items-center justify-center mb-2 w-full border border-green-500 bg-green-900 rounded">
                        Message Received!
                    </AnimatedDiv>
                )}
                <form
                    id="contactForm"
                    onSubmit={submitHandler}
                    className={`flex gap-10 flex-col min-h-full ${classes['form-input']}`}>
                    <div className="flex  justify-between w-auto gap-8">
                        <input
                            placeholder="Your Name"
                            name="first_name"
                            className="w-full"
                            required
                        />
                        <input
                            placeholder="Your Surname"
                            name="last_name"
                            className="w-full"
                            required
                        />
                    </div>
                    <div className="flex w-auto justify-between gap-8">
                        <input
                            placeholder="Your Email"
                            name="email"
                            className="w-full"
                            required
                            type="email"
                        />
                        <input
                            placeholder="Phone Number"
                            name="phone_number"
                            className="w-full"
                            required
                        />
                    </div>
                    <textarea
                        placeholder="Your Message"
                        name="message"
                        className="h-full"
                        required
                    />
                    <AppButton title="Send Message" />
                </form>
            </div>

            <div className="md:w-2/5 flex flex-col gap-6">
                {/* <h1
                    className="2xl:text-6xl md:text-5xl text-4xl text-white leading-loose font-semibold"
                    style={{ lineHeight: 1.25 }}>
                    Let's Get In Touch
                </h1> */}
                <span className="text-end text-gray-500">
                    Tell us about your vision: which challenges are you facing?
                    What are your goals and expectations? What would success
                    look like and how much are you planning to spend to get
                    there?
                </span>
                <div className="flex flex-col gap-3 md:gap-6 items-end text-end">
                    <div className="flex gap-4 whitespace-break-spaces items-start">
                        <span>{CONTACT_INFO.ADDRESS}</span>
                        <img className="mt-1" src={address} />
                    </div>
                    <div className="flex gap-4">
                        <span>{CONTACT_INFO.EMAIL}</span>
                        <img src={email} />
                    </div>
                </div>
            </div>

            {/* <div className="md:1-9/12 ">
                <div
                    className={`${classes['icon-container']} min-h-[40px] h-full w-full justify-center items-center min-w-[70px] flex md:flex-col gap-14`}>
                    {FOOTER_SOCIAL_MEDIA?.map((item, index) => (
                        <a href={item.link} key={index} target="_blank">
                            <FontAwesomeIcon size={'xl'} icon={item.icon} />
                        </a>
                    ))}
                </div>
            </div> */}
        </div>
    );
};

export default ContactFormTransparent;
