import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { BUTTON_TYPE } from '../../../../constants/enum';
import contactHero1 from '../../../../images/contact_hero_1.png';
import contactHero2 from '../../../../images/contact_hero_2.png';
import contactHero3 from '../../../../images/contact_hero_3.png';
import { AnimatedDiv, AppButton, AppTitle } from '../../../../ui';
import { sendEmail } from '../../../../utils/contactUtils';

import classes from './ContactHeroSection.module.css';

type Props = {};

const ContactHeroSection = (props: Props) => {
    const imagesArray = [contactHero1, contactHero2, contactHero3];
    const [activeIndex, setActiveIndex] = useState(0);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setActiveIndex(prev => {
                if (prev === imagesArray.length - 1) return 0;
                else return prev + 1;
            });
        }, 5000);

        return () => clearInterval(sliderInterval);
    }, []);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false);
            }, 1000);
        }
    }, [success]);
    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(e.target);
        sendEmail(e.target, () => setSuccess(true));
    };

    return (
        <div className="relative h-full">
            <AnimatePresence>
                <motion.img
                    key={activeIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    whileInView={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="absolute h-full max-h-[100vh] md:max-h-full w-auto md:min-w-full object-cover overflow-hidden"
                    src={imagesArray[activeIndex]}
                />
            </AnimatePresence>
            <AnimatedDiv
                axis="x"
                axisValue={-100}
                className="flex w-full h-full app-px py-6 md:py-24 items-center rounded-xl md:rounded-3xl">
                <div className="gradient-blur-light md:w-1/2 h-full mt-[10vh] flex flex-col rounded-3xl p-6 md:p-12">
                    <span className="text-4xl md:text-7xl font-bold">
                        CONTACT US
                    </span>
                    <span className="md:text-xl md:my-2">
                        You can fill out the form below to contact us.
                    </span>
                    {success && (
                        <AnimatedDiv
                            axis="y"
                            axisValue={-50}
                            className="p-2 min-h-[50px] flex items-center justify-center mb-2 w-full border border-green-500 bg-green-900 rounded">
                            Message Received!
                        </AnimatedDiv>
                    )}
                    <form
                        onSubmit={submitHandler}
                        className={`flex gap-4 md:gap-8 flex-col  ${classes['form-input']}`}>
                        <input
                            placeholder="Your name"
                            name="first_name"
                            className="w-full"
                            required
                        />
                        <input
                            placeholder="Your Surname"
                            className="w-full"
                            name="last_name"
                            required
                        />
                        <input
                            placeholder="Your Email"
                            className="w-full"
                            name="email"
                            type="email"
                            required
                        />
                        <input
                            placeholder="Your Phone"
                            className="w-full"
                            name="phone_number"
                            required
                        />
                        <textarea
                            placeholder="Your Message"
                            className="w-full"
                            name="message"
                            required
                        />
                        <AppButton
                            type="submit"
                            buttonType={BUTTON_TYPE.white}
                            title="Send Message"
                            // onClick={submitHandler}
                        />
                    </form>
                </div>
            </AnimatedDiv>
        </div>
    );
};

export default ContactHeroSection;
