import { motion } from 'framer-motion';
import React from 'react';

type Props = {
    children: React.ReactNode;
    axis: 'x' | 'y';
    axisValue: number | string;
    [rest: string]: any;
};

const AnimatedDiv = (props: Props) => {
    const { children, axis, axisValue, ...rest } = props;
    return (
        <motion.div
            initial={{
                [axis]: axisValue ?? 100,
                opacity: 0,
            }}
            whileInView={{ [axis]: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            {...rest}>
            {children}
        </motion.div>
    );
};

export default AnimatedDiv;
