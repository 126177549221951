import React from 'react';

import { StatisticsContainer } from '../../../../components';
import { ABOUT_DATA } from '../../../../data/AboutData';
import aboutImg from '../../../../images/about_details.png';
import { AnimatedDiv } from '../../../../ui';

type Props = {};

const AboutDetailsSection = (props: Props) => {
    return (
        <div className="w-full 2xl:text-xl relative">
            <AnimatedDiv
                axis="y"
                axisValue={-100}
                className="flex flex-col gap-6 relative w-full items-center mb-16">
                <h2 className="app-subtitle">Why Modsoft</h2>
                <p className="text-center md:w-2/3 px-6">{ABOUT_DATA.DESC}</p>
            </AnimatedDiv>

            <div className="flex md:flex-row flex-col flex-1 h-auto w-full justify-between ">
                <AnimatedDiv
                    axis="x"
                    axisValue={-100}
                    className="md:w-full md:pl-24 md:px-0 px-6 flex flex-wrap gap-10 2xl:gap-16 order-2 md:order-1">
                    {ABOUT_DATA.WHY_MODSOFT?.map(el => (
                        <div className="flex flex-col md:w-[45%]">
                            <span className="app-subtitle mb-2 leading-none">
                                {el.title}
                            </span>
                            <p>{el.desc}</p>
                        </div>
                    ))}
                </AnimatedDiv>

                {/* <AnimatedDiv
                    axis="x"
                    className="md:w-2/3 2xl:w-3/5 order-1"
                    axisValue={100}>
                    <img
                        className="contain md:pl-20 -mt-10 my-10"
                        src={aboutImg}
                    />
                </AnimatedDiv> */}
            </div>

            <StatisticsContainer />
        </div>
    );
};

export default AboutDetailsSection;
