export const SERVICES_DATA = {
    HERO: {
        title: 'From crafting visually stunning websites to harnessing the power of AI technology,',
        desc: 'our services are designed to elevate your digital presence and propel your business towards unparalleled success. Explore our offerings below and discover how Modsoft can be your trusted partner in navigating the everevolving digital landscape.',
    },
    STEPS: [
        {
            title: 'Planning',
            desc: 'Our projects commence with meticulous planning, collaboratively defining the roadmap with our clients.',
        },
        {
            title: 'Started Project',
            desc: `With the plan in place, we kickstart the project, ensuring a smooth transition
from planning to execution.`,
        },
        {
            title: 'AI Design',
            desc: `Integrating cutting-edge AI design, we enhance functionality and user
experience, staying at the forefront of technology.`,
        },
        {
            title: 'Coding',
            desc: `Our coding experts bring designs to life with clean, efficient, and scalable code,
guaranteeing robust performance.`,
        },
        {
            title: 'Deadlines Finishing',
            desc: `Adhering to deadlines is paramount. We work diligently to meet milestones,
conducting thorough testing for a polished and timely project completion.`,
        },
    ],
    OUR_SERVICES: [
        {
            title: 'Digital Innovation',
            desc: 'Pioneering digital advancements to keep our clients on the forefront of technology.',
        },
        {
            title: 'AI Technology',
            desc: 'Crafting intelligent solutions with AI to provide advanced automation and smart insights.',
        },
        {
            title: 'UX/UI Excellence',
            desc: `Ensuring seamless and aesthetically pleasing
digital experiences across all platforms`,
        },
        {
            title: 'Professional Team',
            desc: `A dedicated team of seasoned professionals
delivering excellence and expertise.`,
        },
        {
            title: 'Strategic Project Management',
            desc: `Meticulous planning and execution
aligned with client objectives for optimal outcomes.`,
        },
        {
            title: 'Cutting-edge Solutions',
            desc: `Thriving on delivering future-ready,
industry-leading solutions for our clients.`,
        },
    ],
};
