import { ROOT_PATH } from '../routes/router.types';

export const NAV_LINKS = [
    {
        to: ROOT_PATH.home,
        title: 'Home',
    },
    {
        to: ROOT_PATH.about,
        title: 'About Us',
    },
    {
        to: ROOT_PATH.services,
        title: 'Services',
    },
    {
        to: ROOT_PATH.portfolio,
        title: 'Portfolio',
    },
];
