import {
    faLinkedin,
    faLinkedinIn,
    faMarkdown,
    faXbox,
} from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { BUTTON_TYPE } from '../../constants/enum';
import { NAV_LINKS } from '../../data/NavLinks';
import logo from '../../images/ModsoftLogo.png';
import menuBox from '../../images/menu-box.svg';
import { ROOT_PATH } from '../../routes/router.types';
import { AppButton } from '../../ui';
import LineBreak from '../../ui/line-break/LineBreak';

const Navbar = () => {
    const [scrolling, setScrolling] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window?.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window?.addEventListener('scroll', handleScroll);

        return () => {
            window?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Add and remove 'menu-open' class when the menu is toggled
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('menu-open');
        };
    }, [isOpen]);

    const linkClasses = ({ isActive }) =>
        isActive ? 'text-yellow-500 font-bold' : 'hover:text-yellow-500';

    const _renderNavLinks = () => (
        <>
            {NAV_LINKS?.map((nav, index) => (
                <NavLink
                    key={index}
                    to={nav.to}
                    onClick={() => isOpen && setIsOpen(false)}
                    className={linkClasses}
                    title={nav.title}>
                    <motion.div
                        initial={{
                            y: -100 + (index + 1) * 10,
                            opacity: 1,
                        }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            duration: 0.1 * (index + 1),
                        }}
                        exit={{ opacity: 0 }}>
                        {nav.title}
                    </motion.div>
                </NavLink>
            ))}

            {/* Call to Action Button */}
            <NavLink to={ROOT_PATH.contact} onClick={() => setIsOpen(false)}>
                <AppButton title="Contact Us" />
            </NavLink>
        </>
    );

    return (
        <>
            <AnimatePresence>
                <motion.nav
                    initial={{ backgroundColor: 'transparent' }}
                    className={`fixed transition-opacity app-px mx-auto w-screen md:h-min top-0 left-0 z-20 ${
                        scrolling && 'gradient-blur-light !border-0'
                    }`}>
                    <div className="2xl:py-6 md:py-4 py-3">
                        <motion.div
                            // transition={{ duration: 5 }}
                            className={`flex items-center md:justify-between justify-center relative`}>
                            {/* Left Logo */}
                            <NavLink
                                to={ROOT_PATH.home}
                                onClick={() => setIsOpen(false)}>
                                <div
                                    // initial={{ y: -100, opacity: 1 }}
                                    // animate={{ y: 0, opacity: 1 }}
                                    // transition={{ duration: 0.3 }}
                                    className={`flex items-center max-h-[2rem]`}>
                                    <img
                                        src={logo}
                                        className="object-contain w-12 md:w-24"
                                    />
                                    {/* <span className="text-white text-3xl font-bold">
                                LOGO
                            </span> */}
                                </div>
                            </NavLink>

                            <div className="items-center text-xl lg:gap-16 md:gap-10 md:flex hidden">
                                {_renderNavLinks()}
                            </div>
                            <div
                                className="absolute right-0 md:hidden"
                                onClick={() => setIsOpen(!isOpen)}>
                                <img className="w-10" src={menuBox} />
                            </div>
                        </motion.div>
                    </div>
                    {!scrolling && !isOpen && <LineBreak />}
                </motion.nav>
            </AnimatePresence>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        className="w-full fixed h-[100vh] left-0 bg-transparent p-4 gradient-blur-light z-20 md:hidden">
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: '100%', opacity: 1 }}
                            exit={{ height: 0, opacity: 0.6 }}
                            transition={{ duration: 0.4 }}
                            style={{
                                background:
                                    'linear-gradient(to right, #231829, #1c2032)',
                            }}
                            className="flex flex-col p-8 justify-center flex-1 max-h-[80dvh] gap-16  rounded-xl border border-[#FF906F] ">
                            <motion.div
                                initial={{ y: -100, opacity: 1 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.3 }}
                                className={`flex w-full justify-between items-center`}>
                                {/* <span className="text-white text-3xl font-bold">
                                    LOGO
                                </span> */}
                                <img
                                    src={logo}
                                    className=" object-contain w-20"
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    size="lg"
                                    onClick={() => setIsOpen(false)}
                                />
                            </motion.div>
                            <div className="flex flex-col justify-center items-center flex-1 gap-8 text-2xl ">
                                {_renderNavLinks()}
                            </div>
                            <div className="w-full flex flex-col gap-4">
                                {/* <span className="text-sm text-gray-400 text-center leading-tight">
                                    {`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
                                </span> */}
                                <a
                                    href="https://www.linkedin.com/company/modsoft-technology-ai-development/mycompany/"
                                    target="_blank">
                                    <FontAwesomeIcon
                                        icon={faLinkedinIn}
                                        size="lg"
                                        onClick={() => {}}
                                    />
                                </a>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Navbar;
