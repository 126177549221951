import { motion } from 'framer-motion';
import React from 'react';

import { PORTFOLIO_DATA } from '../../../../data/PortfolioData';
import circle from '../../../../images/ellipse.svg';
import hero from '../../../../images/porfolio_hero.png';
import waves from '../../../../images/portfolio_waves_bg.png';
import { AnimatedDiv, GradientCircle } from '../../../../ui';

type Props = {};

const PortfolioHeroSection = (props: Props) => {
    const data = [1, 2, 3, 4, 5, 6];

    const _renderServiceBox = (item, index) => (
        <AnimatedDiv
            axis="y"
            axisValue={100}
            transition={{ duration: 0.1 * (index + 1) }}
            className={`absolute animated-glow-div border border-[#58048B] px-4 md:px-8 2xl:px-10 py-2 md:py-4 2xl:py-6 w-28 md:w-56 2xl:w-64 items-center flex text-center rounded-xl md:rounded-2xl ${item.position}`}
            style={{
                background:
                    ' linear-gradient(107deg, rgba(33, 27, 44, 0.80) 16.52%, rgba(33, 27, 44, 0.10) 91.65%)',
                // boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) ',
                backdropFilter: 'blur(25px)',
            }}>
            <span className="font-bold text-sm md:text-xl leading-5">
                {item.title}
            </span>
        </AnimatedDiv>
    );

    const circleAnimationProps = {
        initial: { scale: 0, opacity: 0 },
        whileInView: { scale: 1, opacity: 1 },
    };
    return (
        <div className="md:h-screen h-[500px] md:min-h-full min-h-[500px] flex content-container justify-center relative z-0">
            <AnimatedDiv
                axis="y"
                axisValue={-100}
                className="flex justify-center mx-24 2xl:text-[16vw] xl:text-[14vw] text-[16vw] absolute top-20 items-center leading-none font-black">
                <span className="gradient-text z-1">PORTFOLIO</span>
                {/* <span className="gradient-text z-1 absolute" aria-hidden={true}>
                    PORTFOLIO
                </span> */}
            </AnimatedDiv>
            <img
                src={waves}
                className="w-[200%] md:h-auto h-1/2 md:top-0 top-50 opacity-5 absolute object-cover"
            />

            <div className="flex justify-center left-5 md:left-10 w-full absolute -bottom-16 md:-bottom-10">
                <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    src={hero}
                    className="md:h-[80vh] h-[50vh] object-cover w-auto z-[1]"
                />
                <GradientCircle
                    size={800}
                    colors={[
                        'rgba(166, 145, 92, 0.44) 0%',
                        ' rgba(48, 31, 51, 0.07) 100%',
                    ]}
                />
            </div>
            <div className="absolute flex justify-center items-end z-[-10] md:bottom-0 -bottom-10">
                <motion.img
                    transition={{ duration: 0.6 }}
                    {...circleAnimationProps}
                    src={circle}
                    className="md:w-[55%] w-[70%] absolute"
                    {...circleAnimationProps}
                />
                <motion.img
                    transition={{ duration: 0.5 }}
                    {...circleAnimationProps}
                    src={circle}
                    className="md:w-[65%] w-[85%] absolute opacity-70"
                />
                <motion.img
                    transition={{ duration: 0.4 }}
                    {...circleAnimationProps}
                    src={circle}
                    className="md:w-[75%] opacity-40 2xl:absolute"
                />
                <motion.img
                    transition={{ duration: 0.3 }}
                    {...circleAnimationProps}
                    src={circle}
                    className="hidden 2xl:block w-[95%] opacity-20"
                />
            </div>
            <div className="relative h-2/3 w-full self-end md:mx-32 z-[2]">
                {PORTFOLIO_DATA.HERO_TABS?.map((items, index) =>
                    _renderServiceBox(items, index),
                )}
            </div>
        </div>
    );
};

export default PortfolioHeroSection;
