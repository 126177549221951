import { motion } from 'framer-motion';
import React from 'react';

import { BUTTON_TYPE } from '../../constants/enum';
import { ButtonType } from '../../constants/enumTypes';

import classes from './AppButton.module.css';

interface Props {
    title: string;
    onClick?: (event: any) => void;
    classStyles?: any;
    buttonType?: string;
    [rest: string]: any;
}

const AppButton = (props: Props) => {
    const {
        title,
        onClick,
        classStyles,
        buttonType = BUTTON_TYPE.primary,
        ...rest
    } = props;

    return (
        <motion.button
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            // transition={{ duration: 0.3 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`w-44 h-12 min-h-[45px] md:min-h-[65px] rounded-lg focus:outline-none ${classes[buttonType]}`}
            onClick={onClick}
            {...rest}>
            <span className="md:text-xl font-medium uppercase">{title}</span>
        </motion.button>
    );
};

export default AppButton;
