import React, { useRef, useState } from 'react';

import playIcon from '../../images/playIcon.svg';
import AnimatedDiv from '../animated-div/AnimatedDiv';

interface Props {
    videoUrl: string;
}

const VideoPlayer = ({ videoUrl }: Props) => {
    const [isPlaying, setPlaying] = useState(true);
    const videoRef = useRef(null);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }

            setPlaying(!isPlaying);
        }
    };

    return (
        <AnimatedDiv
            axis="y"
            axisValue={100}
            className="flex items-center justify-center relative mx-6 my-16 md:m-24 h-auto rounded-3xl">
            <video
                ref={videoRef}
                className="w-full max-h-[65vh] overflow-hidden bg-black rounded-3xl bg-cover object-cover"
                // controls
                // onPlay={handlePlayPause}
                onClick={handlePlayPause}
                autoPlay={true}
                loop
                playsInline
                // onPause={handlePlayPause}
            >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Custom Play/Pause Button */}
            {!isPlaying && (
                <div
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                    onClick={handlePlayPause}>
                    <img
                        src={playIcon}
                        alt={'Play'}
                        className="w-16 h-16 md:w-36 md:h-36 2xl:w-auto 2xl:h-auto"
                    />
                </div>
            )}
        </AnimatedDiv>
    );
};

export default VideoPlayer;
