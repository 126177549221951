import React from 'react';

import {
    ClientsLogoContainer,
    ContactFormTransparent,
    HeroBackground,
} from '../../components';
import Navbar from '../../components/navbar/Navbar';
import videoBg from '../../images/video.mp4';
import { AppTitle, VideoPlayer } from '../../ui';

import HomeAboutSection from './sections/home-about-section/HomeAboutSection';
import HomeHeroSection from './sections/home-hero-section/HomeHeroSection';
import HomePortfolioSection from './sections/home-portfolio-section/HomePortfolioSection';
import HomeServicesSection from './sections/home-services-section/HomeServicesSection';

type Props = {};

const HomePage = (props: Props) => {
    return (
        <div className="w-screen overflow-hidden">
            <HeroBackground />
            <HomeHeroSection />
            <ClientsLogoContainer />
            <HomeAboutSection />
            <HomeServicesSection />
            <HomePortfolioSection />
            <VideoPlayer videoUrl={videoBg} />
            <div className="app-px">
                <AppTitle title="Contact us" />
                <ContactFormTransparent />
            </div>
        </div>
    );
};

export default HomePage;
