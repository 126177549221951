import React from 'react';
import { NavLink } from 'react-router-dom';

import { SERVICES_DATA } from '../../../../data/ServicesData';
import serviceHero from '../../../../images/service_hero.png';
import { ROOT_PATH } from '../../../../routes/router.types';
import { AnimatedDiv, AppButton, GradientCircle } from '../../../../ui';

type Props = {};

const ServicesHeroSection = (props: Props) => {
    return (
        <div className="content-container flex md:flex-row flex-col md:h-screen items-center justify-center app-px pb-5">
            <AnimatedDiv
                axis="x"
                axisValue={-100}
                className="flex flex-col md:gap-10 gap-6 2xl:gap-14 md:w-3/5 order-2 md:order-1">
                <span className="text-2xl md:text-5xl 2xl:text-6xl font-bold ">
                    {SERVICES_DATA.HERO.title}
                </span>
                <p className="text-sm md:text-base 2xl:text-xl">
                    {SERVICES_DATA.HERO.desc}
                </p>
                <NavLink to={ROOT_PATH.contact}>
                    <AppButton title="Get Started" buttonType="secondary" />
                </NavLink>
            </AnimatedDiv>
            <AnimatedDiv
                axis="x"
                axisValue={100}
                className="md:w-2/5 relative  order-1">
                <img src={serviceHero} />
                <GradientCircle
                    // size={450}
                    colors={[
                        'rgba(166, 145, 92, 0.64) 0%',
                        ' rgba(48, 31, 51, 0.07) 100%',
                    ]}
                    classesName="right-0 top-0  !h-full !w-full z-[-1]"
                />
            </AnimatedDiv>
        </div>
    );
};

export default ServicesHeroSection;
