import { motion } from 'framer-motion';
import React from 'react';

import { HOME_DATA } from '../../../../data/HomeData';
import dummyimage from '../../../../images/image2.png';
import { AnimatedDiv, AppSlider } from '../../../../ui';

type Props = {};

const PortfolioReferencesSection = (props: Props) => {
    const renderItem = (item, index) => {
        // const {title, description} = item
        return (
            <motion.div
                initial={{ y: 100 + (index + 1) * 10, opacity: 1 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.1 * (index + 1) }}
                key={index}
                className="justify-center w-full md:h-[500px] h-[250px] md:min-h-[500px] 2xl:h-[32rem] rounded-xl md:rounded-3xl gradient-blur-light">
                <div className="mx-4 mt-4 md:mx-10 md:mt-10 h-2/3 rounded-xl md:rounded-3xl overflow-hidden object-cover">
                    <img
                        src={item.image}
                        className="object-cover h-full w-full"
                    />
                </div>
                <div className="mx-6 md:mx-24 md:mt-4 mt-1 flex flex-col items-center">
                    <span className="app-subtitle">{item?.title}</span>
                    <span className="md:text-xl">(2020 - 2023)</span>
                </div>
            </motion.div>
        );
    };
    const renderItemHome = (item, index) => (
        <motion.div
            initial={{ y: -100 + (index + 1) * 10, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.1 * (index + 1) }}
            key={index}
            className="md:min-h-[30vh] border border-[#676767] min-h-[10vh] min-w-[15rem] md:w-[700px] rounded-xl md:rounded-3xl">
            <div
                className="relative md:h-[50vh] h-[20vh] 2xl:h-[45vh] w-[full] overflow-hidden bg-no-repeat bg-center bg-cover rounded-xl md:rounded-3xl"
                style={{
                    backgroundImage: `url(${item.image})`,
                }}>
                <div className="flex flex-1 h-full flex-col w-full p-4 md:p-10 justify-around items-center text-white bg-black bg-opacity-50 opacity-0 rounded-lg hover:opacity-100 transition-opacity duration-300">
                    {/* <h2 className="text-2xl md:text-4xl font-bold mb-4">
                        {item.title}
                    </h2>
                    <div className="border-b !h-1 border-white w-full mb-2 md:mb-4"></div>
                    <p className="text-xs md:text-lg flex flex-1 text-center mb-2 md:mb-4">
                        {item.desc}
                    </p>
                    <button className=" text-base md:text-xl text-white font-bold px-4 md:py-2 rounded-full">
                        Read More
                    </button> */}
                </div>
            </div>
        </motion.div>
    );
    return (
        <div className=" relative flex flex-col md:h-[90vh] h-[40vh] flex-1 items-center justify-center">
            <AnimatedDiv
                axis="y"
                axisValue={-100}
                className="flex w-full justify-center text-[13vw] 2xl:text-[13vw] xl:text-[12vw] items-center leading-none font-black">
                <span
                    className="bg-size-[200% 200%]"
                    style={{
                        color: '#1c2032',
                        background:
                            'linear-gradient(111deg, #ff8e6f 7.73%, #ff6 91.16%)',
                        backgroundSize: '200% 200%',
                        WebkitBackgroundClip: 'text',
                        WebkitTextStroke: '4px transparent',
                    }}>
                    REFERENCES
                </span>
            </AnimatedDiv>

            <div className="w-full h-auto app-px md:my-10 justify-end items-end bottom-0">
                <AppSlider
                    // slideWidth={500}
                    data={HOME_DATA.PORFOLIO.porfolioList}
                    renderItem={(item, index) => renderItemHome(item, index)}
                />
            </div>
        </div>
    );
};

export default PortfolioReferencesSection;
