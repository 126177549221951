import { motion } from 'framer-motion';
import React from 'react';

import circleText from '../../images/modsoft_circle.svg';
import starBurst from '../../images/starburst.svg';

import classes from './LogoRound.module.css';

type Props = {};

const LogoRound = (props: Props) => {
    return (
        <motion.div
            initial={{ scale: 0.7, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            whileHover={{ scale: 1.1, rotate: 40 }}
            // transition={{ duration: 0.3 }}
            style={{
                backgroundImage: `url(${starBurst})`,
                // backgroundSize: '150%',
            }}
            className={`relative md:w-60 md:h-60 w-52 h-52 flex justify-center items-center z-[1] bg-center bg-contain md:bg-[150%]`}>
            {/* <img src={starBurst} className="absolute w-72" /> */}
            <img
                src={circleText}
                className={`w-[50%] sm:w-auto h-auto  z-10 ${classes['rotate']}`}
            />
            <div
                className={`gradient-blur-orange rounded-full w-[4rem] h-[4rem]  sm:w-[7.5rem] sm:h-[7.5rem] -rotate-[20deg] text-center flex items-center justify-center px-2 sm:px-5 absolute`}>
                <span className=" text-black text-xs sm:text-xl font-semibold text-center">
                    Let's Get Started
                </span>
            </div>
        </motion.div>
    );
};

export default LogoRound;
