//
import {
    faInstagram,
    faLinkedinIn,
    faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

export const FOOTER_DATA = [
    {
        column: 'Home',
        components: [
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
        ],
    },
    {
        column: 'About Us',
        components: [
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
        ],
    },
    {
        column: 'Service',
        components: [
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
        ],
    },
    {
        column: 'Contact Us',
        components: [
            { title: 'Random1', link: '/' },
            { title: 'Random1', link: '/' },
        ],
    },
];

export const FOOTER_COPYRIGHT = `2023 Modsoft. All rights reserved`;

export const FOOTER_SOCIAL_MEDIA = [
    { icon: faInstagram, link: 'https://www.instagram.com' },
    { icon: faXTwitter, link: 'https://www.twitter.com' },
    { icon: faLinkedinIn, link: 'https://www.linkedin.com' },
];
