import { motion } from 'framer-motion';
import React from 'react';

type Props = {};

const LineBreak = (props: Props) => {
    return (
        <motion.div
            initial={{ x: -100, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            className="relative flex justify-between mx-2">
            {/* Diamond shape at the left end */}
            <div className="absolute left-0 w-2 h-2 bg-white bg-opacity-40 transform -translate-x-1/2 -translate-y-1/2 rotate-45"></div>

            {/* Grey line */}
            <div className="h-px flex-1 bg-white bg-opacity-40 mx-1"></div>

            {/* Diamond shape at the right end */}
            <div className="absolute right-0 w-2 h-2 bg-white bg-opacity-40 transform translate-x-1/2 -translate-y-1/2 rotate-45"></div>
        </motion.div>
    );
};

export default LineBreak;
