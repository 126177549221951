import React from 'react';
import { NavLink } from 'react-router-dom';

import { ROOT_PATH } from '../../routes/router.types';
import { AppButton } from '../../ui';

import classes from './WorkWithUs.module.css';

type Props = {};

const WorkWithUs = (props: Props) => {
    return (
        <div
            className={`${classes['border-img']}  flex flex-col rounded-3xl overflow-hidden p-1 md:p-2  mx-6 md:mx-24 my-10`}
            style={{
                background:
                    'linear-gradient(90deg, #FF8E6F 0.03%, #FF6 99.96%)',
            }}>
            <div
                className={`${classes['wrapper-container']} py-6 app-px md:py-16 rounded-3xl`}>
                <div className="flex justify-center gap-6 md:gap-10 text-center flex-col items-center ">
                    <span className="md:w-2/3 gradient-text text-3xl 2xl:text-8xl xl:text-7xl font-black">
                        Would you like to work with us?
                    </span>
                    <span className="md:text-xl text-lg">
                        If you have a business idea or want to get consultancy,
                        you can contact us.
                    </span>
                    <NavLink to={ROOT_PATH.contact}>
                        <AppButton title="Start Now" />
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default WorkWithUs;
