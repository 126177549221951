import React from 'react';

import {
    ClientsLogoContainer,
    ContactFormTransparent,
    HeroBackground,
    StatisticsContainer,
    TextLines,
} from '../../components';
import linesBg from '../../images/lines_box_bg.svg';
import videoBg from '../../images/video2.mp4';
import { AppTitle, LineBreak, VideoPlayer } from '../../ui';

import AboutCompanySection from './sections/about-company-section/AboutCompanySection';
import AboutDetailsSection from './sections/about-details-section/AboutDetailsSection';
import AboutHeroSection from './sections/about-hero-section/AboutHeroSection';

type Props = {};

const AboutUsPage = (props: Props) => {
    return (
        <div className="w-screen overflow-hidden">
            <img className="w-full absolute z-[-1] opacity-50" src={linesBg} />
            <HeroBackground />
            <AboutHeroSection />
            <AboutCompanySection />
            <AboutDetailsSection />
            <TextLines />
            <VideoPlayer videoUrl={videoBg} />
            <ClientsLogoContainer />
            <div className="app-px">
                <AppTitle title="Contact Us" />
                <ContactFormTransparent />
            </div>
        </div>
    );
};

export default AboutUsPage;
