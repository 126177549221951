import clientLogo from '../images/Modsoftlogowithtext.svg';

export const CLIENTS_LOGO = [
    {
        name: 'Modsoft',
        logo: clientLogo,
        link: 'https://Modsoft.org/',
    },
    {
        name: 'Modsoft',
        logo: clientLogo,
        link: 'https://Modsoft.org/',
    },
    {
        name: 'Modsoft',
        logo: clientLogo,
        link: 'https://Modsoft.org/',
    },
    {
        name: 'Modsoft',
        logo: clientLogo,
        link: 'https://Modsoft.org/',
    },
    {
        name: 'Modsoft',
        logo: clientLogo,
        link: 'https://Modsoft.org/',
    },
    {
        name: 'Modsoft',
        logo: clientLogo,
        link: 'https://Modsoft.org/',
    },
];
