import React from 'react';

import { ClientsLogoContainer, TextLines } from '../../components';
import videoBg from '../../images/video3.mp4';
import { VideoPlayer } from '../../ui';

import ContactHeroSection from './sections/contact-hero-secion/ContactHeroSection';

type Props = {};

const ContactPage = (props: Props) => {
    return (
        <div className="w-screen overflow-hidden">
            <ContactHeroSection />
            <div className="-my-10">
                <TextLines />
            </div>
            <VideoPlayer videoUrl={videoBg} />
            <ClientsLogoContainer />
        </div>
    );
};

export default ContactPage;
