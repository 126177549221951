import { motion } from 'framer-motion';
import React from 'react';
import Slider from 'react-slick';

import { HOME_DATA } from '../../../../data/HomeData';
import dummyimage from '../../../../images/image2.png';
import { AppSlider, AppTitle, GradientCircle } from '../../../../ui';

type Props = {};

const HomePortfolioSection = (props: Props) => {
    const renderItem = (item, index) => (
        <motion.div
            initial={{ y: -100 + (index + 1) * 10, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.1 * (index + 1) }}
            key={index}
            className="md:min-h-[30vh] border border-[#676767] min-h-[10vh] min-w-[15rem] md:w-[700px] rounded-xl md:rounded-3xl">
            <div
                className="relative md:h-[50vh] h-[20vh] 2xl:h-[45vh] w-[full] overflow-hidden bg-no-repeat bg-center bg-cover rounded-xl md:rounded-3xl"
                style={{
                    backgroundImage: `url(${item.image})`,
                }}>
                <div className="flex flex-1 h-full flex-col w-full p-4 md:p-10 justify-around items-center text-white bg-black bg-opacity-50 opacity-0 rounded-lg hover:opacity-100 transition-opacity duration-300">
                    {/* <h2 className="text-2xl md:text-4xl font-bold mb-4">
                        {item.title}
                    </h2>
                    <div className="border-b !h-1 border-white w-full mb-2 md:mb-4"></div>
                    <p className="text-xs md:text-lg flex flex-1 text-center mb-2 md:mb-4">
                        {item.desc}
                    </p>
                    <button className=" text-base md:text-xl text-white font-bold px-4 md:py-2 rounded-full">
                        Read More
                    </button> */}
                </div>
            </div>
        </motion.div>
    );

    return (
        <div className="app-px mt-24 relative">
            <AppTitle title="Portfolio" />
            <div className="w-full text-white text-lg md:text-xl text-center md:app-px py-10">
                {HOME_DATA.PORFOLIO.desc}
            </div>

            <div className="w-full md:min-h-[500px] min-h-[200px] mt-5 ">
                <AppSlider
                    data={HOME_DATA.PORFOLIO.porfolioList}
                    renderItem={(item, index) => renderItem(item, index)}
                />
            </div>
            <GradientCircle
                // size={600}
                classesName={
                    'absolute top-[30%] left-[10%] !w-[80vw] !h-[60vh] opacity-50'
                }
            />
        </div>
    );
};

export default HomePortfolioSection;
