import { motion } from 'framer-motion';
import React from 'react';

import image from '../../../../images/about-hero.png';
import star from '../../../../images/star_white.png';

import classes from './AboutHeroSection.module.css';

type Props = {};

const AboutHeroSection = (props: Props) => {
    return (
        <div className="content-container flex flex-col md:h-screen h-full md:min-h-full min-h-[380px] items-center justify-center app-px">
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="flex justify-center 2xl:text-[17vw] xl:text-[15vw] text-[17vw] absolute top-20 items-center leading-none font-black">
                <span className={'gradient-text'}>ABOUT US</span>
            </motion.div>

            <motion.div
                initial={{ opacity: 0.7 }}
                animate={{ opacity: 1 }}
                className={`h-2/3 min-h-[14rem] flex md:py-24 py-10 app-px justify-center w-full mt-auto md:rounded-[4.5rem] rounded-[2.5rem] ${classes['bg-image-container']}`}
                style={{
                    backgroundImage: `url(${image})`,
                }}>
                {/* <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className="flex justify-center gradient-blur-light 2xl:h-20 xl:h-16 h-10 md:w-4/5 w-full z-[2] rounded-full overflow-hidden">
                    <input
                        className="w-full text-center bg-transparent text-base md:text-xl 2xl:text-2xl text-gray-800 placeholder-gray-500 font-medium"
                        placeholder="Type your search here..."
                    />
                </motion.div> */}
            </motion.div>
            <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="flex gap-3 md:gap-4 md:px-5 px-3 md:py-10 py-3 justify-evenly items-center md:text-xl 2xl:text-2xl text-sm  font-bold gradient-blur-light 2xl:h-28 xl:h-20 h-auto md:w-[50%] md:-mt-10 -mt-6 2xl:-mt-14 z-[2] rounded-xl md:rounded-2xl overflow-hidden">
                <span>Consultancy</span>
                <img src={star} className="md:w-8 2xl:w-10 w-4 h-auto" />
                <span>Development</span>
                <img src={star} className="md:w-8 2xl:w-10 w-4 h-auto" />
                <span>Design</span>
            </motion.div>
        </div>
    );
};

export default AboutHeroSection;
