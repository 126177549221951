import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

import {
    FOOTER_COPYRIGHT,
    FOOTER_DATA,
    FOOTER_SOCIAL_MEDIA,
} from '../../data/FooterLinks';
import { CONTACT_INFO } from '../../data/HomeData';
import logo from '../../images/Modsoftlogo1.svg';
import address from '../../images/address.svg';
import footer_image from '../../images/footer_left.png';
import email from '../../images/sms-tracking.svg';
import { GradientCircle } from '../../ui';

import classes from './Footer.module.css';

const Footer = () => {
    // const _renderColumns = (title, links, index) => {
    //     return (
    //         <motion.div
    //             initial={{ y: -100, opacity: 1 }}
    //             whileInView={{ y: 0, opacity: 1 }}
    //             transition={{ duration: 0.5 }}
    //             key={index}>
    //             <h3 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4">
    //                 {title}
    //             </h3>
    //             <ul className="flex flex-col gap-3">
    //                 {links?.map((el, index) => (
    //                     <li key={index} className="text-lg md:text-xl">
    //                         <Link to={el.link}>{el.title}</Link>
    //                     </li>
    //                 ))}
    //             </ul>
    //         </motion.div>
    //     );
    // };
    return (
        <motion.footer
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            className={`text-white bottom-0 flex flex-col md:flex-row relative w-full mt-20`}>
            <GradientCircle
                size={500}
                classesName="-right-40 -bottom-2/4"
                colors={[
                    'rgba(255, 142, 111, 0.50) 0%',
                    'rgba(48, 31, 51, 0.07) 100%',
                ]}
            />
            <GradientCircle
                size={500}
                classesName="left-40 -bottom-1/3"
                colors={[
                    'rgba(166, 145, 92, 0.44) 0%',
                    'rgba(48, 31, 51, 0.07) 100%',
                ]}
            />
            <div
                className={`${classes['container']} md:min-h-64 md:max-h-72 flex md:flex-row flex-col pt-10 md:items-end w-full`}>
                {/* Left Side Image */}
                <motion.div
                    initial={{ x: -100, opacity: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="md:w-auto hidden md:flex">
                    <img
                        src={footer_image}
                        alt="Footer Image"
                        className="md:h-[22rem] h-[25rem] -mt-16"
                    />
                </motion.div>

                <div className="w-full flex flex-col mt-2 flex-1 gap-4 md:pr-24 px-10">
                    {/* 4 Columns */}
                    {/* <div className="flex-1 flex md:flex-row flex-wrap gap-10 justify-evenly md:justify-between">
                        {FOOTER_DATA?.map((item, index) =>
                            _renderColumns(item.column, item.components, index),
                        )}
                    </div> */}

                    {/* Social media links */}
                    {/* <div className="mt-auto self-end">
                        <div className="flex space-x-6">
                            {FOOTER_SOCIAL_MEDIA?.map((el, index) => (
                                <a key={index} href={el.link} target="_blank">
                                    <FontAwesomeIcon
                                        size={'lg'}
                                        icon={el.icon}
                                    />
                                </a>
                            ))}
                        </div>
                    </div> */}

                    <div className="w-full flex md:flex-row flex-col gap-5">
                        <div className=" md:w-2/5 flex flex-col">
                            {/* <h2 className="app-subtitle md:mb-3 mb-1">LOGO</h2> */}
                            <img src={logo} className=" object-contain w-24" />
                            {/* <span className="font-semibold text-xl">
                                Year of foundation:
                            </span> */}
                            <span>
                                Modsoft was founded in December 2022. It has
                                been providing services in the field of software
                                since 2022.
                            </span>
                        </div>
                        <div className="md:w-2/3 flex flex-col items-end justify-between gap-5 h-auto">
                            <div className="flex md:flex-row flex-col gap-3 md:gap-8 justify-start md:items-start items-end">
                                <div className="flex gap-2 items-start">
                                    <span className=" whitespace-break-spaces">
                                        {CONTACT_INFO.ADDRESS}
                                    </span>
                                    <img src={address} className="mt-1" />
                                </div>
                                <div className="flex gap-2">
                                    <span>{CONTACT_INFO.EMAIL}</span>
                                    <img src={email} className="mt-1" />
                                </div>
                            </div>
                            <a
                                href="https://www.linkedin.com/company/modsoft-technology-ai-development/mycompany/"
                                target="_blank">
                                <div className="flex border border-white rounded box-border">
                                    <FontAwesomeIcon
                                        size={'xl'}
                                        icon={faLinkedin}
                                    />
                                    <div className="mx-2 font-semibold">
                                        Modsoft
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    {/* Copyright Text */}
                    <div className="py-4 md:self-start self-center">
                        <p className="text-gray-300">
                            &copy; {FOOTER_COPYRIGHT}
                        </p>
                    </div>
                </div>
            </div>
        </motion.footer>
    );
};

export default Footer;
