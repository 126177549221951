import React from 'react';

import { LogoRound } from '../../../../components';
import { ABOUT_DATA } from '../../../../data/AboutData';
import companyShape from '../../../../images/about_company.png';
import liIcon from '../../../../images/about_li_icon.png';
import dashedLine from '../../../../images/dashed_line.svg';
import { AnimatedDiv } from '../../../../ui';

type Props = {};

const AboutCompanySection = (props: Props) => {
    const ABOUT = ABOUT_DATA.ABOUT_COMPANY;

    return (
        <div className="w-full relative md:py-24 py-12 app-px 2xl:text-xl">
            {/* <div className="absolute md:right-[10%] -right-10 top-0">
                <LogoRound />
            </div> */}
            {/* <h2 className="app-subtitle">{ABOUT.FOUNDATION.title}</h2> */}
            {/* <h2 className="app-subtitle mb-8 md:mb-16">{ABOUT.title}</h2> */}
            {/* <p className="md:w-2/3 mb-8 md:mb-16">{ABOUT.FOUNDATION.desc}</p> */}
            <AnimatedDiv
                axis="y"
                axisValue={-100}
                className="flex flex-col gap-6 relative w-full items-center">
                <div className="flex items-center">
                    <img
                        src={dashedLine}
                        className="md:w-auto w-1/2 md:left-0 -left-16 md:top-4 absolute"
                    />
                    <h2 className="app-subtitle">{ABOUT.title}</h2>
                </div>
                {/* <p className="text-center md:w-2/3">{ABOUT.FOUNDATION.desc}</p> */}
            </AnimatedDiv>
            <div className="w-full flex md:flex-row flex-col py-16 gap-10 ">
                <AnimatedDiv axis="x" axisValue={100} className="md:w-1/4">
                    <h2 className="app-subtitle mb-6">{ABOUT.SUCCESS.title}</h2>
                    <ul className="flex flex-col gap-6">
                        {ABOUT.SUCCESS.CHARACTERISTICS?.map(el => (
                            <li className="flex gap-4 items-center">
                                <img className="w-6" src={liIcon} /> {el}
                            </li>
                        ))}
                    </ul>
                </AnimatedDiv>
                <img src={companyShape} className="md:w-1/3" />
                <AnimatedDiv axis="x" axisValue={-100} className="md:w-2/5">
                    <h2 className="md:text-3xl text-xl font-bold mb-4 md:mb-6 whitespace-break-spaces">
                        {ABOUT.MISSION.title}
                    </h2>
                    <p>{ABOUT.MISSION.desc}</p>
                    <br />
                    <br />
                    <br />
                    <h2 className="md:text-3xl text-xl font-bold mb-4 md:mb-6 whitespace-break-spaces">
                        {ABOUT.VISION.title}
                    </h2>
                    <p>{ABOUT.VISION.desc}</p>
                </AnimatedDiv>
            </div>
        </div>
    );
};

export default AboutCompanySection;
