export const ABOUT_DATA = {
    DESC: `Choose Modsoft for a digital journey that transcends expectations – where
creativity meets technology, and every project is a testament to our
commitment to excellence.`,
    WHY_MODSOFT: [
        {
            title: `Professional Team`,
            desc: ` Modsoft boasts a team of seasoned professionals,
each an expert in their respective field. We bring together a collective
passion for innovation and a commitment to delivering exceptional
results.`,
        },
        {
            title: `Innovation and Expertise`,
            desc: `At Modsoft, we thrive on pushing the
boundaries of technology. Our commitment to innovation is coupled
with a deep understanding of industry trends and best practices,
ensuring that our solutions are always ahead of the curve.`,
        },
        {
            title: `Client-Centric Approach`,
            desc: `We prioritize our clients' needs and
objectives, working closely with them to understand their vision and
goals. Our collaborative approach ensures that every project is a true
reflection of the client's aspirations.`,
        },
        {
            title: `Quality Assurance`,
            desc: `Quality is ingrained in every aspect of our work.
From design to development, we adhere to the highest standards to
deliver solutions that are not only visually stunning but also
functionally superior`,
        },
    ],
    ABOUT_COMPANY: {
        title: 'Mission & Vision',
        MISSION: {
            title: 'OUR MISSION \n\nData Security and Privacy:',
            desc: 'Providing reliable software solutions by protecting the data security and privacy of our customers at the highest level.',
        },
        VISION: {
            title: 'OUR VISION \n\nTechnological Leadership:',
            desc: 'To be a software company recognized worldwide as a technological leader and to add value to our customers through continuous innovation.',
        },
        FOUNDATION: {
            title: 'Year of foundation',
            desc: 'Modsoft was founded in December 2022. It provides services in the software industry starting from 2022 computers.',
        },
        SUCCESS: {
            title: 'Secrets of Our Success',
            CHARACTERISTICS: [
                'Diversity and Co-operation',
                'Innovation and Technological Leadership',
                'User Oriented Approach',
                'Data Security and Ethical Values',
            ],
        },
    },
    STATISTICS_DATA: [
        {
            title: 'Consultancy',
            value: '1500',
        },
        {
            title: 'Development',
            value: '5000',
        },
        {
            title: 'Design',
            value: '1000',
        },
    ],
};
