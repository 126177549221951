import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { LogoRound } from '../../../../components';
import { HOME_DATA } from '../../../../data/HomeData';
import homeBgleft from '../../../../images/home_bg_1.png';
import homeBgright from '../../../../images/home_bg_1_right.png';
import home2 from '../../../../images/home_hero2.png';
import home3 from '../../../../images/home_hero3.png';
import star from '../../../../images/star.png';
import starWhite from '../../../../images/star_white.png';
import { LineBreak } from '../../../../ui';

import classes from './HomeHeroSection.module.css';

type Props = {};

const HomeHeroSection = (props: Props) => {
    const [sliderIndex, setSliderIndex] = useState<any>([
        {
            component: (
                <div key={0}>
                    <motion.img
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -200, opacity: 0 }}
                        transition={{ duration: 1 }}
                        // loading="lazy"
                        className="w-[48vw] absolute left-0 md:top-14 top-24 z-[2] object-contain"
                        src={homeBgleft}
                    />
                    <motion.img
                        initial={{ x: 200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 200, opacity: 0 }}
                        transition={{ duration: 1 }}
                        // loading="lazy"
                        className="w-[48vw] absolute right-0  md:top-14 top-24 z-[2]"
                        src={homeBgright}
                    />
                </div>
            ),
        },
        {
            component: (
                <motion.img
                    key={1}
                    initial={{ scale: 0.7, opacity: 0 }}
                    animate={{ scale: 1.2, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 1 }}
                    // loading="lazy"
                    className="w-1/2 md:w-[50vw] absolute left-24 md:left-[25%] bottom-[8%] z-[2] overflow-x-hidden"
                    src={home2}
                />
            ),
        },
        {
            component: (
                <motion.img
                    key={2}
                    initial={{ x: -100, opacity: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    exit={{ x: '50%', opacity: 0 }}
                    transition={{ duration: 1 }}
                    // loading="lazy"
                    className=" h-[50%] md:h-[90vh] absolute right-4 md:right-20 bottom-0 z-[2]"
                    src={home3}
                />
            ),
        },
    ]);
    const [activeIndex, setActiveIndex] = useState(1);

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setActiveIndex(prev => {
                if (prev === sliderIndex.length - 1) return 0;
                else return prev + 1;
            });
        }, 10000);

        return () => clearInterval(sliderInterval);
    }, []);

    const index2 = activeIndex === 2;
    const isMobile = window.innerWidth < 440;
    console.log(window.innerWidth);

    const getRoundLogoPositions = () => {
        if (!isMobile) {
            return index2
                ? { x: '35vw', y: -100 }
                : activeIndex === 1
                ? { x: 100, y: -100 }
                : { x: 0, y: 0 };
        } else
            return index2
                ? { x: '35vw', y: -80 }
                : activeIndex === 1
                ? { x: 0, y: -50 }
                : { x: 0, y: -10 };
    };

    return (
        <div className="content-container flex relative justify-between flex-col w-screen md:h-screen xs:h-auto md:min-h-full min-h-[350px]">
            <div className="flex flex-col gap-4 app-px mx-3 md:mx-24 md:mt-8">
                <motion.div
                    initial={{ x: -100, opacity: 1 }}
                    animate={{ x: 0, opacity: 1 }}
                    className="flex flex-row items-center md:gap-4 gap-2 mt-1 mx-3 md:mx-0 ">
                    <img className="w-5 mb-2 md:w-10 md:mb-0" src={star} />
                    <img className="w-5 mb-2 md:w-10 md:mb-0" src={star} />
                    <div className="text-lg font-semibold text-center md:text-2xl md:text-left">
                        AI Technology Development
                    </div>
                </motion.div>
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, width: '100vw' }}
                        animate={{ opacity: 1, width: '100vw' }}
                        whileInView={{
                            opacity: 1,
                            width: index2
                                ? window.innerWidth < 1660
                                    ? '40vw'
                                    : '45vw'
                                : '100vw',
                        }}
                        transition={{ duration: 0.5 }}
                        className={`flex md:min-w-[40vw] min-w-full  2xl:text-[16vw] xl:text-[15vw] text-[17vw] leading-[0.9] font-black z-[2] transition-all`}>
                        {/* <span className="text-[18pc] leading-none font-black"> */}

                        <motion.span
                            initial={{ opacity: 0, width: '100vw' }}
                            whileInView={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                            className={`animated-glow stroke-2 transition-all ${
                                classes[`modsoft-` + activeIndex]
                            }`}>
                            MODSOFT
                        </motion.span>

                        {/* </span> */}
                    </motion.div>
                </AnimatePresence>
            </div>
            <AnimatePresence>
                {sliderIndex[activeIndex]?.component}
            </AnimatePresence>

            <motion.div
                whileInView={getRoundLogoPositions()}
                transition={{ duration: 0.5 }}
                className="w-1/3 h-1/3 bottom-[10%] left-1 md:bottom-0 2xl:bottom-20 md:left-[18%] absolute md:w-auto md:h-auto z-10">
                <LogoRound />
            </motion.div>
            <motion.div
                initial={{ y: 100, opacity: 1, x: '40vw' }}
                animate={{ y: 0, opacity: 1, x: '40vw' }}
                whileInView={{
                    x: index2 ? 0 : '40vw',
                    y: activeIndex === 1 && !isMobile ? -50 : 0,
                }}
                transition={{ duration: 0.5 }}
                className={`mx-3 app-px md:mx-24 app-px mb-6 bottom-0 flex-col flex gap-2 md:gap-4 w-full z-[4]`}>
                <div className="flex 2xl:flex gap-4">
                    <img className="w-5 h-auto md:w-10" src={starWhite} />
                    <img className="w-5 h-auto md:w-10" src={starWhite} />
                </div>
                <span className="2xl:w-1/3 xl:w-2/4 w-[55%] 2xl:text-xl xl:text-lg text-[10px] font-medium">
                    {HOME_DATA.BANNER[activeIndex]}
                </span>
            </motion.div>
        </div>
    );
};

export default HomeHeroSection;
