import { motion } from 'framer-motion';
import React from 'react';

import { ABOUT_DATA } from '../../data/AboutData';
import { AnimatedDiv } from '../../ui';

type Props = {};

const StatisticsContainer = (props: Props) => {
    const _renderLine = () => (
        <div
            className="w-0.5 2xl:w-1 h-full min-h-[3rem]"
            style={{
                background:
                    ' radial-gradient(100.49% 50.24% at 50% 50%, #0B0B0B 0%, rgba(0, 0, 0, 0.00) 100%)',
            }}
        />
    );
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex md:gap-4 gap-1 md:m-24 mx-6 my-12 md:px-5 md:py-5 py-3 justify-evenly items-center text-black w-auto font-bold bg-white 2xl:h-36 xl:h-32 z-[2] rounded-2xl">
            {ABOUT_DATA.STATISTICS_DATA?.map((item, index) => (
                <>
                    {!!index && _renderLine()}
                    <AnimatedDiv
                        axis="x"
                        axisValue={-100}
                        className="flex flex-col justify-center items-center">
                        <span className="md:text-4xl text-lg">
                            {item.value}+
                        </span>
                        <span className="md:text-2xl text-sm">
                            {item.title}
                        </span>
                    </AnimatedDiv>
                </>
            ))}
        </motion.div>
    );
};

export default StatisticsContainer;
