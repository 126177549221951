import { createBrowserRouter } from 'react-router-dom';

import {
    AboutUsPage,
    ContactPage,
    HomePage,
    PortfolioPage,
    ServicesPage,
} from '../pages';

import RootRoutePage from './RootRoutePage';
import { ROOT_PATH } from './router.types';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootRoutePage />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            { path: '*', element: <HomePage /> },
            { path: ROOT_PATH.about, element: <AboutUsPage /> },
            { path: ROOT_PATH.services, element: <ServicesPage /> },
            { path: ROOT_PATH.portfolio, element: <PortfolioPage /> },
            { path: ROOT_PATH.contact, element: <ContactPage /> },
        ],
    },
]);

export default router;
