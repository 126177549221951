import portfolioImage1 from '../images/Portfolio1.jpg';
import portfolioImage2 from '../images/Portfolio2.jpg';
import portfolioImage3 from '../images/Portfolio3.jpg';
import portfolioImage4 from '../images/Portfolio4.jpg';
import dummyimage from '../images/image2.png';

export const HOME_DATA = {
    BANNER: [
        'Welcome to Modsoft, where innovation meets expertise in the realm of digital solutions. Established with a passion for pushing the boundaries of technology.',
        'Modsoft is a leading company specializing in Website creation, AI technology development, UI/UX design, Domain and AI-driven project management.',
        'Welcome to Modsoft, where innovation meets expertise in the realm of digital solutions. Established with a passion for pushing the boundaries of technology.',
    ],
    ABOUT: `At Modsoft, we believe in the transformative power of technology to enhance businesses and elevate user experiences. Our journey began with a vision to create a dynamic hub of digital excellence, and today, we stand proud as a dedicated team committed to shaping the future of digital landscapes.\n\nDriven by a commitment to excellence, Modsoft brings together a team of seasoned professionals who thrive on challenges and excel in crafting innovative solutions. With a blend of creativity and technical prowess, we embark on every project with the mission to redefine industry standards and exceed client expectations.`,
    SERVICES: [
        {
            title: 'Website Creation',
            desc: ' Unleash the full potential of your online presence with our bespoke website creation services. From visually stunning designs to seamless functionality, we bring your brand to life in the digital realm.',
        },
        {
            title: 'AI Technology Development',
            desc: `Stay ahead of the curve with our cutting-edge AI technology development services. Whether it's machine learning, natural language processing, or computer vision, we empower your business with intelligent solutions.`,
        },
        {
            title: 'UI/UX Design',
            desc: 'Elevate user engagement with our UI/UX design expertise. We focus on creating interfaces that not only captivate but also enhance user satisfaction and interaction. Coding: Our coding wizards bring ideas to life through clean, efficient, and scalable code. From front-end development to robust back-end solutions, we ensure your digital assets are built for success.',
        },
        {
            title: 'AI-Driven Project Management',
            desc: 'Embrace efficiency with our AI-driven project management solutions. From task automation to intelligent analytics, we streamline your projects for optimal results.',
        },
    ],
    PORFOLIO: {
        desc: `Explore our portfolio to delve into the spectrum of innovative projects we've undertaken. From cutting-edge technology implementations to AI-powered solutions, our portfolio exemplifies the depth of our technical expertise and showcases the transformative impact we've made.`,
        porfolioList: [
            {
                title: 'Portfolio Title 1',
                image: portfolioImage1,
                desc: 'SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
            {
                title: 'Portfolio Title 2',
                image: portfolioImage2,
                desc: 'SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
            {
                title: 'Portfolio Title 3',
                image: portfolioImage3,
                desc: 'SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
            {
                title: 'Portfolio Title 4',
                image: portfolioImage4,
                desc: 'SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
        ],
    },
};

export const CONTACT_INFO = {
    EMAIL: 'info@modsoft.org',
    ADDRESS: `Bay square building 1 - 302, \nBusiness Bay - DUBAI / UAE`,
};
