import { motion } from 'framer-motion';
import React from 'react';

import { SERVICES_DATA } from '../../../../data/ServicesData';
import circle from '../../../../images/service_circle.svg';
import icon from '../../../../images/stickynote.svg';
import { AnimatedDiv } from '../../../../ui';

type Props = {};
const data = SERVICES_DATA.STEPS;

const RenderCircle = ({ index }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative flex items-center w-3/5 justify-center">
        <span className="absolute md:text-5xl text-2xl top-[25%] font-bold">
            {index + 1}
        </span>
        <motion.img
            initial={{ rotate: '45deg' }}
            whileInView={{ rotate: 0 }}
            transition={{ duration: 0.5 }}
            src={circle}
            className="md:min-w-[115px] top-0"
        />
    </motion.div>
);

const RenderCard = ({ item, className }: any) => (
    <div
        className={`flex flex-col flex-1 w-full border-2 p-6 md:p-8 2xl:p-10 border-[#FF8E6F] gap-2 2xl:gap-4 rounded-[28px] justify-center ${className}`}>
        <img
            src={icon}
            className="gradient-blur-orange w-12 p-2 md:p-3 xl:w-12 rounded-xl 2xl:p-4 2xl:w-16 2xl:rounded-2xl"
        />
        <span className="text-lg md:text-2xl 2xl:text-3xl font-semibold">
            {item.title}
        </span>
        <span className="text-sm md:text-base 2xl:text-lg">{item.desc}</span>
    </div>
);

const RenderServiceCard = ({ item, index }: any) => (
    <div className="flex md:flex-row flex-col w-full items-center ">
        <div className="md:w-2/4 flex items-center order-2 md:order-1">
            {index % 2 !== 0 && (
                <AnimatedDiv
                    axis="x"
                    axisValue={100}
                    className="flex flex-col md:flex-row items-center">
                    <RenderCard item={item} />
                    <div className="md:flex md:border-t-2 border-r-2 md:h-auto h-16 md:w-1/6 w-auto border-dashed border-[#FF8E6F]" />
                </AnimatedDiv>
            )}
        </div>
        <div className="flex flex-col relative items-center order-1">
            <RenderCircle index={index} />

            <div
                className={`border-r-4 md:absolute md:-bottom-[100%] h-16 md:h-[100%] 2xl:-bottom-[130%] 2xl:h-[130%] w-1 border-dashed border-[#484739] ${
                    index === data.length - 1 && 'md:hidden flex'
                }`}
            />
        </div>
        <div className="md:w-2/4 flex items-center order-2 md:order-1">
            {index % 2 === 0 && (
                <AnimatedDiv
                    axis="x"
                    axisValue={-100}
                    className="flex flex-col md:flex-row items-center">
                    <div
                        className={`md:flex md:border-t-2 border-r-2 md:h-auto h-16 md:w-1/6 w-auto border-dashed border-[#FF8E6F] order-2 md:order-1 ${
                            index === data.length - 1 && 'hidden md:flex'
                        }`}
                    />
                    <RenderCard item={item} className="order-1 md:order-2" />
                </AnimatedDiv>
            )}
        </div>
    </div>
);

const ServicesStepsSection = (props: Props) => {
    return (
        <div className="flex flex-col py-8 md:py-24 app-px relative items-center">
            <div className="flex md:w-1/3 flex-wrap left-24 md:absolute mb-6">
                <span className="gradient-text text-5xl md:text-7xl font-bold">
                    How does it work?
                </span>
                <span className="my-4 md:text-lg text-sm">
                    Here you can see our working system step by step.
                </span>
            </div>
            {data?.map((item, index) => (
                <RenderServiceCard item={item} index={index} />
            ))}
        </div>
    );
};

export default ServicesStepsSection;
