import React from 'react';

import {
    ClientsLogoContainer,
    HeroBackground,
    StatisticsContainer,
} from '../../components';
import WorkWithUs from '../../components/work-with-us/WorkWithUs';

import ServicesHeroSection from './sections/services-hero-section/ServicesHeroSection';
import ServicesOurServicesSection from './sections/services-our-services-section/ServicesOurServicesSection';
import ServicesStepsSection from './sections/services-steps-section/ServicesStepsSection';

type Props = {};

const ServicesPage = (props: Props) => {
    return (
        <div className="w-screen overflow-hidden">
            <HeroBackground />
            <ServicesHeroSection />
            <ServicesOurServicesSection />
            <ServicesStepsSection />
            <StatisticsContainer />
            <ClientsLogoContainer />
            <WorkWithUs />
        </div>
    );
};

export default ServicesPage;
