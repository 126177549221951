import React, { FC } from 'react';

interface GradientCircleProps {
    colors?: string[];
    size?: number | string;
    classesName?: any;
}

const GradientCircle = ({ colors, size, classesName }: GradientCircleProps) => {
    const defaultHeight = size || 420;
    const gradientColors = colors || [
        'rgba(255, 142, 111, 0.50) 0%',
        'rgba(48, 31, 51, 0.07) 100%',
    ]; // Default colors

    const gradientStyle: React.CSSProperties = {
        width: size || 420,
        height: `${defaultHeight}px`,
        background: `radial-gradient(circle, ${gradientColors.join(', ')})`,
    };

    return (
        <div
            className={`rounded-full absolute blur-2xl ${classesName}`}
            style={gradientStyle}></div>
    );
};

export default GradientCircle;
