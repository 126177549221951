import { motion } from 'framer-motion';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { HOME_DATA } from '../../../../data/HomeData';
import image from '../../../../images/home_about.svg';
import { ROOT_PATH } from '../../../../routes/router.types';
import { AppButton, AppTitle } from '../../../../ui';

type Props = {};

const HomeAboutSection = (props: Props) => {
    return (
        <div className="app-px app-my flex items-center md:mb-24 mb-20">
            <div className="flex min-h-[80vh] flex-col md:flex-row items-center relative">
                <motion.div
                    initial={{ x: 100, opacity: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className=" md:w-2/4 md:absolute right-0">
                    <img
                        src={image}
                        alt="Right Column Image"
                        className="inset-0 w-full h-full object-cover"
                    />
                </motion.div>
                <motion.div
                    initial={{ x: -100, opacity: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="md:w-[55%] h-auto flex flex-col gap-6">
                    <div className="w-[85%]">
                        <AppTitle title="About us" />
                    </div>
                    <span className="text-white-600 2xl:text-2xl md:text-xl md:mb-4 whitespace-break-spaces">
                        {HOME_DATA.ABOUT}
                    </span>
                    <NavLink to={ROOT_PATH.about}>
                        <AppButton title="About Us" />
                    </NavLink>
                </motion.div>
            </div>
        </div>
    );
};

export default HomeAboutSection;
