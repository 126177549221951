import emailjs from '@emailjs/browser';

const config = {
    SERVICE_ID: 'service_otahjwp',
    TEMPLATE_ID: 'template_wijgd29',
    PUBLIC_KEY: 'W6_XlqHqoXt5ozjux',
};

export const sendEmail = (form, successCallback?: () => void) => {
    console.log('EMAIL');

    emailjs
        .sendForm(
            config.SERVICE_ID,
            config.TEMPLATE_ID,
            form,
            config.PUBLIC_KEY,
        )
        .then(
            result => {
                form.reset();
                successCallback && successCallback();
                console.log(result.text);
            },
            error => {
                console.log(error.text);
            },
        );
};
