import { motion } from 'framer-motion';
import React from 'react';

import classes from './CardTitleDescNumber.module.css';

interface CardTitleDescNumberProps {
    title: string;
    description: string;
    index: number;
}

const CardTitleDescNumber: React.FC<CardTitleDescNumberProps> = ({
    title,
    description,
    index,
}) => {
    return (
        <motion.div
            initial={{ background: 'transparent' }}
            animate={{ background: 'transparent' }}
            whileHover={{
                background:
                    ' linear-gradient(111deg,  rgba(221, 221, 221, 0.8) 17.46%, rgba(221, 221, 221, 0.1) 83.36% )',
            }}
            transition={{ duration: 10 }}
            className={`flex relative ${classes['box-container']}`}>
            {/* Left section with title and description */}
            <div className="flex-1 p-6 z-[1]">
                <h2 className="md:text-3xl text-2xl font-bold md:mb-4 mb-2">
                    {title}
                </h2>
                <p className="text-white 2xl:text-lg text-sm">{description}</p>
            </div>

            {/* Right section with index number */}
            <div className="absolute right-0 flex-1 p-6 text-white text-center opacity-10 italic">
                <span className="text-9xl font-bold">{`${index})`}</span>
            </div>
        </motion.div>
    );
};

export default CardTitleDescNumber;
