import React from 'react';

import { AnimatedDiv } from '../../ui';

import classes from './TextLines.module.css';

type Props = {};

const TextLines = (props: Props) => {
    const textToRepeat = `FAST AND HIGH QUALITY`; // Replace with your desired text
    const repeatCount = 50; // Adjust the number of repetitions as needed

    const repeatedText = new Array(repeatCount)
        .fill(` THIS IS FIRST FAST AND HIGH QUALITY `)
        .join(`  ●  `);

    const repeatedText2 = new Array(repeatCount)
        .fill(` FAST AND HIGH QUALITY `)
        .join(`  ●  `);
    return (
        <div className="relative h-40 flex items-center">
            <AnimatedDiv
                axis="y"
                axisValue={100}
                className="bg-yellow-400 flex absolute w-[calc(100vw+20px)] -ml-6 md:p-6 p-2 md:text-2xl md:!rotate-2 !rotate-3">
                <div className={classes['marquee-content']}>
                    <span>{repeatedText}</span>
                </div>
                <div className={classes['marquee-content']} aria-hidden={true}>
                    <span>{repeatedText}</span>
                </div>
            </AnimatedDiv>
            <AnimatedDiv
                axis="y"
                axisValue={-100}
                className="bg-orange-400 flex absolute w-[calc(100vw+20px)] -ml-6 md:p-6 p-2 md:text-2xl md:!-rotate-2 !-rotate-3">
                <div className={classes['marquee-content-2']}>
                    <span>{repeatedText2}</span>
                </div>
                <div
                    className={classes['marquee-content-2']}
                    aria-hidden={true}>
                    <span>{repeatedText}</span>
                </div>
            </AnimatedDiv>
        </div>
    );
};

export default TextLines;
