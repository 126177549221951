import { motion } from 'framer-motion';
import React from 'react';

type Props = {
    title: string;
    subTitle?: string;
};

const AppTitle = ({ title, subTitle }: Props) => {
    return (
        <motion.div
            initial={{ x: -100, opacity: 1 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-1 justify-between items-end md:mb-4">
            <h1 className="uppercase text-[2.5rem] md:text-[5rem] leading-none font-bold text-white">
                {title}
            </h1>
            <h3 className="uppercase text-2xl md:text-4xl text-gray-600 italic">
                {subTitle}
            </h3>
        </motion.div>
    );
};

export default AppTitle;
