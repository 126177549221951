import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Footer, Navbar } from '../components';

type Props = { children: any };

const RootRoutePage = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        return window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div>
            <Navbar />
            <div className="overflow-x-hidden w-screen">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default RootRoutePage;
