export const PORTFOLIO_DATA = {
    HERO_TABS: [
        {
            title: 'Digital Innovation',
            position: 'top-[10%] md:left-10 left-5',
        },
        {
            title: 'AI Technology',
            position: 'top-[10%] md:right-10 right-5',
        },
        {
            title: 'UX/UI Excellence',
            position: 'top-[40%] left-[10%] ',
        },
        {
            title: 'Professional Team',
            position: 'top-[40%] right-[10%]',
        },
        {
            title: 'Strategic Project Management',
            position: 'top-[70%] md:left-[20%] left-[15%]',
        },
        {
            title: 'Cutting-edge Solutions',
            position: 'top-[70%] md:right-[20%] right-[15%]',
        },
    ],
};
