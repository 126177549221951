import React from 'react';
import {
    ResponsiveContainer,
    StackedCarousel,
} from 'react-stacked-center-carousel';

import arrowLeft from '../../images/arrow_left.svg';
import arrowRight from '../../images/arrow_right.svg';

import './AppSlider.css';

type Props = {
    renderItem: (item: object, index: number) => React.ReactNode;
    data: Array<object | number | string> | any;
    currentVisibleSlide?: number;
    slideWidth?: number;
};

const AppSlider = (props: Props) => {
    const { renderItem, data, currentVisibleSlide, slideWidth } = props;
    const ref = React.useRef(null);

    return (
        <div className="relative flex items-center">
            <img
                className=" top-[40%] left-0 md:left-2.5 z-10"
                src={arrowLeft}
                onClick={() => {
                    ref.current?.goBack();
                }}
            />
            <ResponsiveContainer
                carouselRef={ref}
                render={(parentWidth, carouselRef) => {
                    // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
                    // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
                    // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
                    // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
                    let currentVisibleSlide = 5;
                    if (parentWidth <= 1440) currentVisibleSlide = 3;
                    // if (parentWidth <= 1080) currentVisibleSlide = 1;
                    return (
                        <StackedCarousel
                            ref={carouselRef}
                            slideComponent={({ dataIndex }) =>
                                renderItem(data[dataIndex], dataIndex)
                            }
                            slideWidth={
                                slideWidth ??
                                (parentWidth < 800 ? parentWidth - 80 : 750)
                            }
                            carouselWidth={parentWidth}
                            data={data}
                            currentVisibleSlide={currentVisibleSlide}
                            maxVisibleSlide={5}
                            useGrabCursor
                        />
                    );
                }}
            />

            <img
                className="right-0 md:right-2.5 z-10"
                src={arrowRight}
                onClick={() => {
                    ref.current?.goNext(6);
                }}
            />
        </div>
    );
};

export default AppSlider;
