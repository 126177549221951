import React from 'react';

import { CLIENTS_LOGO } from '../../data/ClientsLogo';
import clientLogo from '../../images/clientLogo1.png';
import { AppSlider, LineBreak } from '../../ui';

import classes from './ClientLogoContainer.module.css';

type Props = {};

const ClientsLogoContainer = (props: Props) => {
    const renderLogo = (item, index) => {
        return (
            <div className="group flex justify-center">
                <div className="absolute group-hover:block hidden -top-12 bg-[#0B0B0B] p-2 rounded">
                    {item.name}
                </div>
                {/* <a href={item.link} target="_blank"> */}
                <img
                    key={index}
                    className="w-1/6 min-w-[100px] md:min-w-[200px] opacity-30 hover:opacity-100"
                    src={item.logo}
                />
                {/* </a> */}
            </div>
        );
    };
    return (
        <div className="w-full app-px mb-5 md:mb-16 2xl:py-16 flex flex-col gap-10 md:gap-20">
            <LineBreak />
            <div className="flex gap-10 md:gap-20">
                <div className={`flex gap-10 md:gap-20 ${classes['scroll']}`}>
                    {CLIENTS_LOGO?.map((item, index) =>
                        renderLogo(item, index),
                    )}
                </div>
                <div
                    className={`flex gap-10 md:gap-20 ${classes['scroll']}`}
                    aria-hidden={true}>
                    {CLIENTS_LOGO?.map((item, index) =>
                        renderLogo(item, index),
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClientsLogoContainer;
