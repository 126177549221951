import { ButtonSize, ButtonType } from './enumTypes';

export const BUTTON_TYPE: Record<ButtonType, string> = {
    primary: 'primary',
    secondary: 'secondary',
    transparent: 'transparent',
    white: 'white',
};

export const BUTTON_SIZE: Record<ButtonSize, string> = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};
