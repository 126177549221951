import { motion } from 'framer-motion';
import React from 'react';

import { HOME_DATA } from '../../../../data/HomeData';
import serviceImg from '../../../../images/home_services.svg';
import { AppTitle, CardTitleDescNumber } from '../../../../ui';

type Props = {};

const HomeServicesSection = () => {
    return (
        <div className="app-px">
            <AppTitle title="Services" />
            <div className="flex flex-col md:flex-row w-full md:gap-10 gap-4">
                <motion.div
                    initial={{ x: -100, opacity: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="md:w-1/2 md:mt-0 mt-8">
                    <img src={serviceImg} />
                </motion.div>
                <motion.div
                    initial={{ x: 100, opacity: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="md:w-1/2 flex justify-between flex-col md:py-10">
                    {HOME_DATA.SERVICES?.map((item, index) => (
                        <CardTitleDescNumber
                            title={item.title}
                            description={item.desc}
                            index={index + 1}
                        />
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default HomeServicesSection;
